<template>
  <div class="py-4 container-fluid">
    <a class="btn btn-sm btn-success" style="margin-right: 10px;" :href="apiUrl+'export-excel/daily-activity?project_type='+search.project_type+'&po_number='+search.po_number+'&c_date='+search.c_date+'&client='+search.client+'&owner='+search.owner+'&site='+search.site+'&kinds='+search.kinds+'&due_date='+search.due_date+''" target="_BLANK"><i class="fa fa-file-excel-o"></i> Export to Excel</a>
    <a class="btn btn-sm btn-primary" style="margin-right: 10px;" :href="apiUrl+'print-pdf/daily-activity?project_type='+search.project_type+'&po_number='+search.po_number+'&c_date='+search.c_date+'&client='+search.client+'&owner='+search.owner+'&site='+search.site+'&kinds='+search.kinds+'&due_date='+search.due_date+''" target="_BLANK"><i class="fa fa-file-pdf-o"></i> Export to PDF</a>
    <div class=" row">
      <div class="col-12">
          <div class="card"> 
            <div class="row">
              <div class="col-lg-4">
                <div class="card-header pb-0">
                  <h6>Work Order List</h6>
                </div>
              </div>
              <div class="col-lg-4">
              </div>
              <div class="col-lg-4 float-right">
                <argon-button
                  style="margin-right: 10px; margin-left: 60px;"
                  class="mt-4"
                  variant="gradient"
                  color="secondary"
                  size="sm"
                  @click="filter()"
                ><i class="fa fa-filter fa-sm" aria-hidden="true"></i> Filter</argon-button>
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="success"
                  size="sm"
                  @click="create()"
                ><i class="fa fa-plus fa-sm" aria-hidden="true"></i> Add New</argon-button>
              </div>
            </div>
            
            <div class="card-body px-0 pt-0 pb-2 mt-4">
              <div class="table-responsive p-0 scroll">
                <table class="table table-hover table-striped align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">No</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">WO NO</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">PO NUMBER</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">C DATE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">CLIENT</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">OWNER</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">PROJECT</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">SITE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">KINDS</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">QTY</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">WEIGHT (TON)</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">RUPIAH</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">DOLLAR</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">EXCHANGE RATE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">DEL DUE DATE</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">REMARK</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">DEL DATE ACTUAL (SALES)</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">DEL</th>
                      <th class="text-center text-uppercase text-white text-xxs font-weight-bolder">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, i) in table.data" :key="i" @click="setBg(i)" :style="(backgroundRed == i) ? 'background-color: #7FFF00; cursor: pointer;' : 'cursor: pointer;' ">
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">{{ i + 1 }}</span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.wo_code }} </span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.po_number }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ moment(row.c_date).locale('id').format('LL') }} </span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.client }} </span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.owner }} </span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.project }} </span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.site }} </span>
                      </td>
                      <td class="align-middle text-left text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.kinds }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.qty }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.weight }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ convertRp(row.rupiah) }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ convertDlr(row.dollar) }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ convertDlr(row.exchange_rate) }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ moment(row.due_date).locale('id').format('LL') }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.remark }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ moment(row.del_date_actual).locale('id').format('LL') }} </span>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <span class="text-secondary text-xs font-weight-bold">  {{ row.del }} </span>
                      </td>
                      <td class="align-middle text-center">
                        <i class="fas fa-edit fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="edit(row.id)"></i>
                        <i class="fa fa-trash fa-sm" aria-hidden="true" style="cursor: pointer; margin-right: 20px;" @click="remove(row.id)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <div class="float-right">
                <span class="font-weight-bold text-danger ">TOTAL ROWS : {{ table.data.length }}</span>
              </div>
              <div class="float-right">
                <base-pagination :page-count="pagination.page_count" :value="pagination.page" @input="changePage" :total="pagination.page_count" class="justify-content-end"></base-pagination>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>

  <!-- =======  MODAL ADD DATA ======= -->
  <div class="container">
    <vue-final-modal v-model="form.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{form.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="form.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <!-- <label for="example-text-input" class="form-control-label mt-3">Project Type <span style="color: red;" v-if="form.title == 'Add Data'">*</span></label>
        <select class="form-select" aria-label="Default select example" v-model="workOrderData.project_type" v-if="form.title == 'Add Data'">
          <option selected>Project Type</option>
          <option value="D">D</option>
          <option value="M">M</option>
        </select> -->
        <label for="example-text-input" class="form-control-label mt-3">WO NO <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="WO NO" v-model="workOrderData.wo_code">
        <label for="example-text-input" class="form-control-label mt-3">PO Number <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="PO Number" v-model="workOrderData.po_number">
        <label for="example-text-input" class="form-control-label mt-3">C Date <span style="color: red;">*</span></label>
        <input type="date" class="form-control" placeholder="C Date" v-model="workOrderData.c_date">
        <label for="example-text-input" class="form-control-label mt-3">Client <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Client" v-model="workOrderData.client">
        <label for="example-text-input" class="form-control-label mt-3">Owner <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Owner" v-model="workOrderData.owner">
        <label for="example-text-input" class="form-control-label mt-3">Project </label>
        <input type="text" class="form-control" placeholder="Project" v-model="workOrderData.project">
        <label for="example-text-input" class="form-control-label mt-3">Site <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Site" v-model="workOrderData.site">
        <label for="example-text-input" class="form-control-label mt-3">Kinds <span style="color: red;">*</span></label>
        <input type="text" class="form-control" placeholder="Kinds" v-model="workOrderData.kinds">
        <label for="example-text-input" class="form-control-label mt-3">Qty <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Qty" v-model="workOrderData.qty">
        <label for="example-text-input" class="form-control-label mt-3">Weight <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Weight" v-model="workOrderData.weight">
        <label for="example-text-input" class="form-control-label mt-3">Rupiah <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Rupiah" v-model="workOrderData.rupiah">
        <label for="example-text-input" class="form-control-label mt-3">Dollar <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Dollar" v-model="workOrderData.dollar">
        <label for="example-text-input" class="form-control-label mt-3">Exchange Rate <span style="color: red;">*</span></label>
        <input type="number" class="form-control" placeholder="Exchange Rate" v-model="workOrderData.exchange_rate">
        <label for="example-text-input" class="form-control-label mt-3">Due Date <span style="color: red;">*</span></label>
        <input type="date" class="form-control" placeholder="Due Date" v-model="workOrderData.due_date">
        <label for="example-text-input" class="form-control-label mt-3">Remark </label>
        <textarea class="form-control" v-model="workOrderData.remark" placeholder="Remark"></textarea>
        <label for="example-text-input" class="form-control-label mt-3">Del Date Actual </label>
        <input type="date" class="form-control" placeholder="Del Date Actual" v-model="workOrderData.del_date_actual">
        <label for="example-text-input" class="form-control-label mt-3">Del </label>
        <input type="text" class="form-control" placeholder="Del" v-model="workOrderData.del">
        <!-- <label for="example-text-input" class="form-control-label mt-3">Attachment </label> -->
        <!-- <input type="file" class="form-control" placeholder="Attachment" @change="filesChange" > -->
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-4"> 
        </div>
        <div class="col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="form.show = true">Close</argon-button>
        </div>
        <div class="col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="save()">Save</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

  <!-- =======  MODAL FILTER ======= -->
  <div class="container">
    <vue-final-modal v-model="formFilter.show" classes="modal-container" content-class="modal-content" :z-index="10000">
      <!-- header -->
      <div class="row">
        <div class="col-11 float-left">
          <span class="modal__title">{{formFilter.title}}</span>
        </div>
        <div class="col-1 float-right">
          <i style="cursor: pointer;" class="fa fa-times" aria-hidden="true" @click="formFilter.show = false"></i>
        </div>
      </div><hr>
      <!-- end header -->
      <div class="modal__content container">
        <label for="example-text-input" class="form-control-label mt-3">Project Type </label>
        <select class="form-select" aria-label="Default select example" v-model="search.project_type">
          <option selected>Project Type</option>
          <option value="D">D</option>
          <option value="M">M</option>
        </select>
        <label for="example-text-input" class="form-control-label mt-3">PO Number </label>
        <input type="text" class="form-control" placeholder="PO Number" v-model="search.po_number" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">C Date </label>
        <input type="date" class="form-control" placeholder="C Date" v-model="search.c_date" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Client </label>
        <input type="text" class="form-control" placeholder="Client" v-model="search.client" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Owner </label>
        <input type="text" class="form-control" placeholder="Owner" v-model="search.owner" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Site </label>
        <input type="text" class="form-control" placeholder="Site" v-model="search.site" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Kinds </label>
        <input type="text" class="form-control" placeholder="Kinds" v-model="search.kinds" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Qty </label>
        <input type="number" class="form-control" placeholder="Qty" v-model="search.qty" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Weight </label>
        <input type="number" class="form-control" placeholder="Weight" v-model="search.weight" v-on:keyup.enter="get()">
        <label for="example-text-input" class="form-control-label mt-3">Due Date </label>
        <input type="date" class="form-control" placeholder="Due Date" v-model="search.due_date" v-on:keyup.enter="get()">

        <!-- <label for="example-text-input" class="form-control-label mt-3">Date Range</label>
        <flat-pickr :config="{ mode: 'range',}" class="form-control" v-model="search.date"/> -->
      </div>
      <!-- footer -->
      <div class="row float-right mt-3">
        <div class="col-4"> 
        </div>
        <div class="col-3" style="margin-right: 20px;">
          <argon-button  variant="gradient" color="secondary" size="sm" width="1" @click="search = {}">Clear</argon-button>
        </div>
        <div class="col-2">
          <argon-button variant="gradient" color="success" size="sm" width="1" @click="get()">Filter</argon-button>
        </div>
      </div>
      <!-- end footer -->
    </vue-final-modal>
   </div>

</template>

<script>

import ArgonButton from "@/components/ArgonButton.vue";
import { VueFinalModal } from 'vue-final-modal'
// import ArgonPagination from "@/components/ArgonPagination.vue";
// import ArgonPaginationItem from "@/components/ArgonPaginationItem.vue";
import Api from '@/helpers/api';
import config from '@/configs/config';
import { toast } from 'vue3-toastify';
// import Multiselect from 'vue-multiselect'
import 'vue3-toastify/dist/index.css';
// import flatPickr from 'vue-flatpickr-component';
// import 'flatpickr/dist/flatpickr.css';
import BasePagination from "@/components/BasePagination";
var moment = require('moment');
import workOrder from '@/services/procurement/workOrder.service';


export default {
  name: "tables",
  components: {
    ArgonButton,
    VueFinalModal,
    // Multiselect,
    // flatPickr,
    BasePagination
  },
  data() {
    return {
      pagination: {
        page_count: '',
        page: 1,
      },
      moment:moment,
      table: {
        data: []
      },
      tableProject: {
        data: []
      },
      tableActivity: {
        data: []
      },
      tableGroup: {
        data: []
      },
      tableEmployee: {
        data: []
      },
      form: {
        add: true,
        title: "Add Data",
        show: false
      },
      formImport: {
        add: true,
        title: "Import Data Setting Approval",
        show: false
      },
      formFilter: {
        add: true,
        title: "Filter",
        show: false
      },
      dataImport: '',
      onLoading: false,
      tabelError: {
        data: []
      },
      storageUrl : config.storageUrl,
      workOrderData: {},
      search: {
        project_type: '',
        po_number: '',
        c_date: '',
        client: '',
        owner: '',
        site: '',
        kinds: '',
        due_date: '',
      },
      searchMenu: '',
      apiUrl :config.apiUrl,
      backgroundRed: null,

    };
  },
  mounted(){
    this.get();
    this.tokenApi = 'Bearer '+localStorage.getItem('token');
  },
  methods: {
    nameWithLang ({ project_number, project_name }) {
      return `${project_number} | ${project_name}`
    },
    get(param){
      let context = this;               
      Api(context, workOrder.index({project_type: this.search.project_type, po_number: this.search.po_number, c_date: this.search.c_date, client: this.search.client, owner: this.search.owner, site: this.search.site, kinds: this.search.kinds, due_date: this.search.due_date, page: context.pagination.page})).onSuccess(function(response) {    
          context.table.data            = response.data.data.data.data;
          context.pagination.page_count = response.data.data.data.last_page

          context.notify('Data Retrieved Successfully', 'success')
      }).onError(function(error) {                    
          if (error.response.status == 404) {
              context.table.data = [];
          }
          context.notify('Data Retrieved Failed', 'error')
      }).onFinish(function() { 
         context.formFilter.show  = false;
      })
      .call()
    },
    filter() {
      this.formFilter.add   = true;
      this.formFilter.show  = true;
      this.formFilter.title = "Filter";
      this.onLoading        = false;
      this.pagination.page  = 1 ;
    },
    create() {
      this.form.add   = true;
      this.form.show  = true;
      this.form.title = "Add Data";
      this.workOrderData   = {};
    },
    edit(id) {
      let context = this;               
      Api(context, workOrder.show(id)).onSuccess(function(response) {
          context.workOrderData = response.data.data;
          context.form.show     = true;
          context.form.title    = 'Edit Data';   
      })
      .call()        
    },
    save(){
      let api      = null;
      let context  = this;
      let formData = new FormData();

      if (context.workOrderData.wo_code != undefined && context.workOrderData.po_number != undefined && context.workOrderData.c_date != undefined && context.workOrderData.client != undefined && context.workOrderData.owner != undefined && context.workOrderData.site != undefined && context.workOrderData.kinds != undefined && context.workOrderData.qty != undefined && context.workOrderData.weight != undefined && context.workOrderData.due_date != undefined) {
        formData.append('wo_code', context.workOrderData.wo_code);
        formData.append('po_number', context.workOrderData.po_number);
        formData.append('c_date', context.workOrderData.c_date);
        formData.append('client', context.workOrderData.client);
        formData.append('owner', context.workOrderData.owner);
        formData.append('project', (context.workOrderData.project == undefined) ? '-' : context.workOrderData.project);
        formData.append('site', context.workOrderData.site);
        formData.append('kinds', context.workOrderData.kinds);
        formData.append('qty', context.workOrderData.qty);
        formData.append('weight', context.workOrderData.weight);
        formData.append('rupiah', context.workOrderData.rupiah);
        formData.append('dollar', context.workOrderData.dollar);
        formData.append('exchange_rate', context.workOrderData.exchange_rate);
        formData.append('due_date', context.workOrderData.due_date);
        formData.append('remark', (context.workOrderData.remark == undefined) ? '' : context.workOrderData.remark);
        formData.append('del_date_actual', (context.workOrderData.del_date_actual == undefined) ? '' : context.workOrderData.del_date_actual);
        formData.append('del', (context.workOrderData.del == undefined) ? '' : context.workOrderData.del);
        // formData.append('attachment', context.workOrderData.attachment);
      }else{
        return alert('The Red Star Field is Mandatory')
      }

      if (context.form.title == 'Add Data') {
          api = Api(context, workOrder.create(formData));
      } else {
          api = Api(context, workOrder.update(this.workOrderData.id, formData));
      }
      // eslint-disable-next-line no-unused-vars
      api.onSuccess(function(response) {
        context.get();
        context.form.show = false;
        context.notify((context.form === 'Add Data') ? 'Data Berhasil di Simpan' : 'Data Berhasil di Update', 'success')
      // eslint-disable-next-line no-unused-vars
      }).onError(function(error) { 
        context.notify((context.form != 'Add Data') ? 'Data Gagal di Simpan' : 'Data Gagal di Update', 'error')                   
      }).onFinish(function() {  
      })
      .call();
    },
    remove(id) {
      var r = confirm("Are you sure you want to delete this data ?");
      if (r == true) {
        let context = this;
        Api(context, workOrder.delete(id)).onSuccess(function(response) {
            context.get();
            context.notify('Data Successfully Deleted', 'success')
        }).call();
      }
    },
    filesChange(e) {
        this.workOrderData.attachment = e.target.files[0];
    },
    changePage(page){
      this.pagination.page = page;
      this.get();
    },
    convertRp(bilangan) {
      if (bilangan) {
        var number_string = bilangan.toString(),
            sisa    = number_string.length % 3,
            rupiah  = number_string.substr(0, sisa),
            ribuan  = number_string.substr(sisa).match(/\d{3}/g);

        if(ribuan){
          var separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
          return rupiah
        }else{
          return bilangan
        }
      }
    },
    convertDlr(bilangan) {
      // if (bilangan) {
      //   var number_string = bilangan.toString(),
      //       sisa    = number_string.length % 3,
      //       rupiah  = number_string.substr(0, sisa),
      //       ribuan  = number_string.substr(sisa).match(/\d{3}/g);

      //   if(ribuan){
      //     var separator = sisa ? '.' : '';
      //     rupiah += separator + ribuan.join('.');
      //     return rupiah
      //   }else{
      //     return bilangan
      //   }
      // }

      var data =  Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR"
      }).format(bilangan);

      return data.substr(3)
    },
    setBg(id) {
      this.backgroundRed = id
    },
    notify(message, type) {
      if (type == 'success') {
        toast.success(message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        }); // ToastOptions
      }else{
        toast.error(message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        }); // ToastOptions
      }
    },

  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width: 500px;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__title {
  font-size: 1rem;
  font-weight: 600;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.scroll { 
  overflow: auto; 
  height: 500px; 
}
.scroll thead th { 
  position: sticky; 
  top: 0; 
  z-index: 100; 
  background-color: #6495ED;
}

/*for freze ke samping*/
.scroll table td:nth-child(2) {
  position: sticky;
  left: 0;
  background-color: #F0E68C;
}
</style>
